var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.assists.tool.isNotEmpty(_vm.bankActiveClass)),expression:"assists.tool.isNotEmpty(bankActiveClass)"}],class:_vm.bankActiveClass},[_vm._m(0),_c('div',{staticClass:"form-wrap"},[_c('s-section',{ref:"sectionForm",attrs:{"primary-key":"sectionId","actionParameters":_vm.parameters,"saveParameters":{
                externalKey: 'sectionData',
                payload: {
                  sectionId: this.parameters.sectionId,
                },
               },"data-template-code":"D23142B4AB60B756D41B6A9C3EB51D4A","section-template-code":"04AF5F16198A5C577CFEAA75CB209DFF"},on:{"syncData":_vm.sectionData,"syncFormSettings":_vm.shareFormSetting},model:{value:(_vm.layouts.data.currentData),callback:function ($$v) {_vm.$set(_vm.layouts.data, "currentData", $$v)},expression:"layouts.data.currentData"}}),(_vm.layouts.data.alertControl)?_c('v-alert',{staticClass:"mt-6",attrs:{"border":"left","colored-border":"","type":"error","elevation":"2"}},[_vm._v(" Your information is incomplete or contains invalid data. Please click 'EDIT' to make changes. ")]):_vm._e(),_c('div',{staticClass:"d-flex justify-center mt-10"},[_c('a',{staticClass:"f-s-20",on:{"click":function($event){_vm.layouts.dialog.changeBankForm=true}}},[_vm._v("EDIT")])])],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"700"},model:{value:(_vm.layouts.dialog.changeBankForm),callback:function ($$v) {_vm.$set(_vm.layouts.dialog, "changeBankForm", $$v)},expression:"layouts.dialog.changeBankForm"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline p-10 m-b-15"},[_vm._v("Bank")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.layouts.dialog.changeBankForm = false}}},[_c('v-icon',[_vm._v("close")])],1)],1),_c('v-card-text',[_c('s-form',{ref:"bankInformation",attrs:{"dataTemplateCode":_vm.dataSources.original.templateCode,"primaryKey":"sectionId","actionParameters":_vm.parameters,"saveParameters":{
            externalKey: 'sectionData',
            payload: {
              sectionId: this.parameters.sectionId,
            },
          },"form-setting-from-section":_vm.layouts.data.editableFormSetting},on:{"syncTemplate":_vm.syncTemplate,"blurAction":_vm.blurAction,"saveCallBack":_vm.saveCallBack,"loadingCallBack":_vm.loadingCallBack}})],1),_c('v-card-actions',[_c('web-btn',{staticClass:"mr-5",attrs:{"height":50,"width":"40%","loading":_vm.layouts.loading.bankMapping},on:{"click":_vm.onClickSave}},[_vm._v(" Save ")])],1)],1)],1),_c('div',{staticClass:"mt-10 d-flex justify-end"},[_c('web-btn',{staticClass:"mr-5",attrs:{"height":50,"theme":'normal',"width":140},on:{"click":function($event){return _vm.$emit('back')}}},[_vm._v("Back ")]),_c('web-btn',{attrs:{"height":50,"width":140},on:{"click":_vm.onClickNext}},[_vm._v("Continue ")])],1),_c('s-dialog',{attrs:{"title":"Add Bank Name"},scopedSlots:_vm._u([{key:"dialog-content",fn:function(){return [_c('v-form',{ref:"bankNameForm"},[_c('v-text-field',{staticClass:"mt-2",attrs:{"label":"Bank Name","rules":_vm.dataSources.original.rules},model:{value:(_vm.dataSources.current.addBankName),callback:function ($$v) {_vm.$set(_vm.dataSources.current, "addBankName", $$v)},expression:"dataSources.current.addBankName"}})],1)]},proxy:true},{key:"dialog-actions",fn:function(){return [_c('v-btn',{staticClass:"next-btn add-bank-name",on:{"click":_vm.onClickAdd}},[_vm._v("OK")])]},proxy:true}]),model:{value:(_vm.layouts.dialog.addBankName),callback:function ($$v) {_vm.$set(_vm.layouts.dialog, "addBankName", $$v)},expression:"layouts.dialog.addBankName"}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"guidance-title"},[_c('div',{staticClass:"f-w-500 f-s-24 mb-5"},[_vm._v("Connect your bank so that we can determine your loan amount.")]),_c('div',{staticClass:"f-s-16"},[_vm._v("This should be the checking account that receives your paycheck.")])])
}]

export { render, staticRenderFns }