<template>
  <div class="guidance">
    <div class="head-panel">
      <!--      <slot name="guidance-navigator"></slot>-->
      <div class="progress-step-content">
        <div class="step-box"
             :style="layouts.data.step === 1 ? 'background: var(--theme_primary)' : 'background: transparent'"
        >
          <v-icon size="20" color="#FFFFFF">
            person
          </v-icon>
        </div>
        <span class="solid-line"></span>
        <div class="step-box"
             :style="(layouts.data.step === 2 ? 'background: var(--theme_primary)' : layouts.data.step > 2 ? 'background: transparent' : 'background: rgba(255, 255, 255, 0.2)')"
        >
          <v-icon size="20" :color="(layouts.data.step >= 2 ? '#FFFFFF' : 'rgba(255, 255, 255, 0.5)')">
            groups
          </v-icon>
        </div>
        <span class="solid-line"></span>
        <div class="step-box"
             :style="(layouts.data.step === 3 ? 'background: var(--theme_primary)' : layouts.data.step > 3 ? 'background: transparent' : 'background: rgba(255, 255, 255, 0.2)')"
        >
          <v-icon size="20" :color="(layouts.data.step >= 3 ? '#FFFFFF' : 'rgba(255, 255, 255, 0.5)')">
            account_balance
          </v-icon>
        </div>
        <span class="solid-line"></span>
        <div class="step-box"
             :style="layouts.data.step === 4 ? 'background: var(--theme_primary)' : 'background: rgba(255, 255, 255, 0.2)'"
        >
          <v-icon size="20" :color="(layouts.data.step === 4 ? '#FFFFFF' : 'rgba(255, 255, 255, 0.5)')">
            playlist_add_check
          </v-icon>
        </div>
      </div>
    </div>
    <div class="main-panel">
      <div class="main-content">
        <template v-if="layouts.data.step === 1">

          <web-guidance-personal
            v-page-behavior="{eventSource: assist.dataType.EventSourceEnum.GUIDANCE_ENTER_LEAVE_PERSONAL_INFORMATION.value, path: $route.path}"
            :parameters="{
                sectionId: layouts.data.personalSectionId
              }"
            @verification="verificationHandler"
            @next="nextHandler"
          ></web-guidance-personal>
        </template>

        <template v-else-if="layouts.data.step === 2">
          <web-guidance-employment
            v-page-behavior="{eventSource: assist.dataType.EventSourceEnum.GUIDANCE_ENTER_LEAVE_EMPLOYMENT_INFORMATION.value, path: $route.path}"
            :parameters="{
                sectionId: layouts.data.employmentSectionId
              }"
            @back="backHandler"
            @verification="verificationHandler"
            @next="nextHandler"
          ></web-guidance-employment>
        </template>

        <template v-else-if="layouts.data.step === 3">
          <web-guidance-bank
            v-page-behavior="{eventSource: assist.dataType.EventSourceEnum.GUIDANCE_ENTER_LEAVE_BANK_INFORMATION.value, path: $route.path}"
            :parameters="{
                sectionId: layouts.data.bankSectionId
              }"
            @verification="verificationHandler"
            @back="backHandler"
            @next="nextHandler"
          ></web-guidance-bank>
        </template>

        <template v-else-if="layouts.data.step === 4">
          <web-guidance-decision-logic
            :verificationData="layouts.data.verificationData"
            :ibv-provider="layouts.data.ibvProvider"
            @back="backHandler"
          ></web-guidance-decision-logic>
        </template>

      </div>
    </div>
  </div>
</template>

<script>
import { OnlineApi } from '@/api'
import { Tool, DataType } from '@/assets/js/util'

import WebGuidancePersonal from '@/views/guidance/WebGuidancePersonal'
import WebGuidanceEmployment from '@/views/guidance/WebGuidanceEmployment'
import WebGuidanceBank from '@/views/guidance/WebGuidanceBank'
import WebGuidanceDecisionLogic from '@/views/guidance/WebGuidanceDecisionLogic'

const tool = new Tool()
export default {
  name: 'web-guidance',
  components: {
    WebGuidancePersonal,
    WebGuidanceEmployment,
    WebGuidanceBank,
    WebGuidanceDecisionLogic
  },
  data: () => ({
    assist: {
      tool: tool,
      dataType: DataType
    },
    layouts: {
      data: {
        verificationData: {},
        step: 1,
        ibvProvider: 0,
        personalSectionId: null,
        employmentSectionId: null,
        bankSectionId: null
      }
    }
  }),
  watch: {
    'store.getters.getStep': {
      handler (val) {
        this.step = val
      },
      immediate: true,
      deep: true
    }
  },
  created () {
    this.getIbvProvider()
    this.getSectionId()
  },
  methods: {
    getIbvProvider () {
      OnlineApi.getProvider(this.$store.getters.getPortfolioId, (result) => {
        this.layouts.data.ibvProvider = result
      })
    },
    getSectionId () {
      OnlineApi.listSection((res) => {
        res.forEach((item) => {
          switch (item.configProperties.sectionName) {
            case 'Personal':
              this.layouts.data.personalSectionId = item.sectionId
              break
            case 'Employment':
              this.layouts.data.employmentSectionId = item.sectionId
              break
            case 'Bank Info':
              this.layouts.data.bankSectionId = item.sectionId
              break
            default:
              break
          }
        })
      })
    },
    nextHandler () {
      this.layouts.data.step = this.layouts.data.step + 1
    },
    backHandler () {
      this.layouts.data.step = this.layouts.data.step - 1
    },
    verificationHandler (parameter) {
      const allowedKeys = ['accountNo', 'routingNo', 'firstName', 'bankName', 'lastName', 'accountType', 'email', 'cellPhone']

      for (const key of Object.keys(parameter)) {
        if (allowedKeys.includes(key)) {
          this.$set(this.layouts.data.verificationData, key, parameter[key])
        }
      }
    }
  }
}
</script>
<style lang="sass" scoped>
.guidance
  height: 100%
  background-color: #F5F5F7

.head-panel
  background-color: var(--theme_secondary)
  height: fit-content
  display: flex
  justify-content: center
  border-bottom-left-radius: 15px
  border-bottom-right-radius: 15px

.main-panel
  padding: 32px 64px
  height: calc(100% - 72px)

.main-content
  margin: 48px auto
  height: calc(100% - 96px)
  width: 900px

.progress-step-content
  width: 1184px
  display: flex
  justify-content: space-between
  align-items: center
  height: 70px

.step-box
  width: 32px
  height: 32px
  border-radius: 50%
  display: flex
  align-items: center
  justify-content: center

.solid-line
  height: 1px
  border: 1px dashed #eef1f8
  flex-grow: 0.5

</style>
