<template>
  <div :class="bankActiveClass" v-show="assists.tool.isNotEmpty(bankActiveClass)">
    <div class="guidance-title">
      <div class="f-w-500 f-s-24 mb-5">Connect your bank so that we can determine your loan amount.</div>
      <div class="f-s-16">This should be the checking account that receives your paycheck.</div>
    </div>
    <div class="form-wrap">
      <s-section v-model="layouts.data.currentData"
                 ref="sectionForm"
                 primary-key="sectionId"
                 :actionParameters="parameters"
                 :saveParameters="{
                  externalKey: 'sectionData',
                  payload: {
                    sectionId: this.parameters.sectionId,
                  },
                 }"
                 @syncData="sectionData"
                 @syncFormSettings="shareFormSetting"
                 data-template-code="D23142B4AB60B756D41B6A9C3EB51D4A"
                 section-template-code="04AF5F16198A5C577CFEAA75CB209DFF">
      </s-section>

      <v-alert
        v-if="layouts.data.alertControl"
        class="mt-6"
        border="left"
        colored-border
        type="error"
        elevation="2"
      >
        Your information is incomplete or contains invalid data. Please click 'EDIT' to make changes.
      </v-alert>

      <div class="d-flex justify-center mt-10">
        <a @click="layouts.dialog.changeBankForm=true" class="f-s-20">EDIT</a>
      </div>
    </div>

    <v-dialog persistent v-model="layouts.dialog.changeBankForm" max-width="700">
      <v-card>
        <v-card-title>
          <span class="headline p-10 m-b-15">Bank</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="layouts.dialog.changeBankForm = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <s-form
            ref="bankInformation"
            :dataTemplateCode="dataSources.original.templateCode"
            primaryKey="sectionId"
            :actionParameters="parameters"
            :saveParameters="{
              externalKey: 'sectionData',
              payload: {
                sectionId: this.parameters.sectionId,
              },
            }"
            :form-setting-from-section="layouts.data.editableFormSetting"
            @syncTemplate="syncTemplate"
            @blurAction="blurAction"
            @saveCallBack="saveCallBack"
            @loadingCallBack="loadingCallBack"
          ></s-form>
        </v-card-text>
        <v-card-actions>
          <web-btn
            class="mr-5"
            :height="50"
            width="40%"
            :loading="layouts.loading.bankMapping"
            @click="onClickSave"
          >
            Save
          </web-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="mt-10 d-flex justify-end">
      <web-btn
        class="mr-5"
        :height="50"
        :theme="'normal'"
        :width="140"
        @click="$emit('back')"
        >Back
      </web-btn>
      <web-btn
        :height="50"
        :width="140"
        @click="onClickNext"
      >Continue
      </web-btn>
    </div>

    <s-dialog v-model="layouts.dialog.addBankName" title="Add Bank Name">
      <template #dialog-content>
        <v-form ref="bankNameForm">
        <v-text-field class="mt-2" label="Bank Name" :rules="dataSources.original.rules" v-model="dataSources.current.addBankName"></v-text-field>
        </v-form>
      </template>
      <template #dialog-actions>
        <v-btn class="next-btn add-bank-name" @click="onClickAdd">OK</v-btn>
      </template>
    </s-dialog>
  </div>
</template>
<script>
import { InfrastructureApi } from '@/api'
import { Tool } from '@/assets/js/util'
import WebBtn from '@/components/base/WebBtn'
import SSection from '@/components/base/SSection'

export default {
  name: 'web-guidance-bank',
  components: {
    WebBtn,
    SSection
  },
  props: {
    parameters: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      assists: {
        tool: new Tool()
      },
      dataSources: {
        original: {
          templateCode: 'D23142B4AB60B756D41B6A9C3EB51D4A',
          rules: [
            (v) => this.assists.tool.isNotEmpty(v) || 'Bank Name can\'t be empty'
          ]
        },
        current: {
          addBankName: ''
        }
      },
      layouts: {
        loading: {
          bankMapping: false,
          update: false
        },
        dialog: {
          addBankName: false,
          changeBankForm: false
        },
        data: {
          currentData: {},
          alertControl: false,
          editableFormSetting: {}
        }
      },
      bankActiveClass: ''
    }
  },
  watch: {
    'layouts.dialog.changeBankForm': {
      handler (val) {
        if (val && this.assists.tool.isNotEmpty(this.$refs.bankInformation)) {
          this.$refs.bankInformation.load()
        }
      }
    }
  },
  methods: {
    onClickSave () {
      if (!this.layouts.loading.update) {
        this.$refs.bankInformation.saveForm()
      }
    },
    loadingCallBack (loading) {
      this.layouts.loading.update = loading
    },
    syncTemplate () {
      const bankInformation = this.$refs.bankInformation
      bankInformation.load()
      // 设置它里面的bankName永远为true
      bankInformation.formsSettings[
        this.dataSources.original.templateCode
      ].elements.find(
        (res) => res.elementKey === 'bankName'
      ).controlProperty.disabled = true
    },
    sectionData (data) {
      this.$emit('verification', data)
      this.bankActiveClass = 'animate__animated animate__backInLeft'
    },
    shareFormSetting (data) {
      this.layouts.data.editableFormSetting = data
    },
    bankMappingCallback (bankName) {
      this.$refs.bankInformation.current.bankName = bankName
      if (this.assists.tool.isEmpty(bankName)) {
        this.$refs.bankInformation.formsSettings[this.dataSources.original.templateCode].elements.find(i => i.elementKey === 'bankName').controlProperty.disabled = false
      }
      this.layouts.loading.bankMapping = false
    },
    blurAction (key, data) {
      if (
        key === 'routingNo' &&
        this.assists.tool.isNotEmpty(data.routingNo) &&
        data.routingNo.length === 9
      ) {
        this.layouts.loading.bankMapping = true
        // 通过监听返回的Routing No去请求Bank Info，并更新Bank Name
        InfrastructureApi.mapBankInfo(
          data.routingNo,
          (res) => {
            if (this.assists.tool.isNotEmpty(res)) {
              this.bankMappingCallback(res.customer_name)
            } else {
              this.bankMappingCallback(null)
            }
          },
          () => {
            this.bankMappingCallback(null)
          }
        )
      }
    },
    onClickNext () {
      this.$refs.sectionForm.$refs.observer.validate().then(validResult => {
        if (validResult) {
          this.$emit('next')
        } else {
          this.layouts.data.alertControl = true
        }
      })
    },
    onClickAdd () {
      if (this.$refs.bankNameForm.validate()) {
        this.layouts.dialog.addBankName = false
        this.$refs.bankInformation.current.bankName = this.dataSources.current.addBankName
        this.dataSources.current.addBankName = ''
      }
    },
    saveCallBack (data) {
      this.$emit('verification', data)
      this.$refs.bankInformation.$refs.observer.validate().then((validResult) => {
        if (validResult) {
          this.layouts.data.currentData = data
          this.layouts.dialog.changeBankForm = false
          this.layouts.data.alertControl = false
          this.layouts.loading.bankMapping = false
        }
      })
    }
  }
}
</script>
<style lang="sass" scoped>
.guidance-title
  font-size: 24px
  text-align: center
  margin-bottom: 32px

.form-wrap
  width: 600px
  margin: auto

.next-btn
  width: 136px
  height: 48px !important
  background: var(--theme_primary) !important
  color: #FFFFFF

.back-btn
  width: 136px
  height: 48px !important
  background: #fffefe !important
  border: 1px solid #333640
  box-shadow: 0 4px 16px 0 rgba(92, 125, 171, 0.35)
  color: var(--theme_primary)
  margin-right: 24px

.add-bank-name
  width: 100px
  height: 40px !important
</style>
