var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.assists.tool.isNotEmpty(_vm.employmentActiveClass)),expression:"assists.tool.isNotEmpty(employmentActiveClass)"}],class:_vm.employmentActiveClass},[_c('div',{staticClass:"guidance-title"},[_vm._v("Please confirm your information")]),_c('s-section',{ref:"sectionForm",attrs:{"primary-key":"sectionId","actionParameters":_vm.parameters,"saveParameters":{
              externalKey: 'sectionData',
              payload: {
                sectionId: this.parameters.sectionId,
              },
             },"data-template-code":"282E321E2F2337233E6363FF327C37B2","section-template-code":"5FB543C5284927E58396114863BC5DC1"},on:{"syncData":_vm.sectionData,"syncFormSettings":_vm.shareFormSetting},model:{value:(_vm.layouts.data.currentData),callback:function ($$v) {_vm.$set(_vm.layouts.data, "currentData", $$v)},expression:"layouts.data.currentData"}}),(_vm.layouts.data.alertControl)?_c('v-alert',{staticClass:"mt-6",attrs:{"border":"left","colored-border":"","type":"error","elevation":"2"}},[_vm._v(" Your information is incomplete or contains invalid data. Please click 'EDIT' to make changes. ")]):_vm._e(),_c('div',{staticClass:"d-flex justify-center mt-10"},[_c('a',{staticClass:"f-s-20",on:{"click":function($event){_vm.layouts.dialog.changeEmploymentForm=true}}},[_vm._v("EDIT")])]),_c('v-dialog',{attrs:{"persistent":"","max-width":"700"},model:{value:(_vm.layouts.dialog.changeEmploymentForm),callback:function ($$v) {_vm.$set(_vm.layouts.dialog, "changeEmploymentForm", $$v)},expression:"layouts.dialog.changeEmploymentForm"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline p-10 m-b-15"},[_vm._v("Employment")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.layouts.dialog.changeEmploymentForm = false}}},[_c('v-icon',[_vm._v("close")])],1)],1),_c('v-card-text',[_c('s-form',{ref:"employerInformation",attrs:{"dataTemplateCode":"282E321E2F2337233E6363FF327C37B2","primaryKey":"sectionId","actionParameters":_vm.parameters,"saveParameters":{
            externalKey: 'sectionData',
            payload: {
              sectionId: this.parameters.sectionId,
            }
          },"form-setting-from-section":_vm.layouts.data.editableFormSetting},on:{"syncTemplate":function($event){return _vm.$refs.employerInformation.load()},"saveCallBack":_vm.handleCallBack,"loadingCallBack":_vm.loadingCallBack}})],1),_c('v-card-actions',[_c('web-btn',{staticClass:"mr-5",attrs:{"height":50,"width":"40%"},on:{"click":_vm.onClickSave}},[_vm._v(" Save ")])],1)],1)],1),_c('div',{staticClass:"mt-10 d-flex justify-end"},[_c('web-btn',{staticClass:"mr-5",attrs:{"height":50,"theme":'normal',"width":140},on:{"click":_vm.onClickBackIcon}},[_vm._v(" Back ")]),_c('web-btn',{attrs:{"height":50,"width":140},on:{"click":_vm.onClickContinue}},[_vm._v("Continue ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }