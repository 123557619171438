<template>
  <div>
    <!--  展示IBV之前，先校验黑名单-->
    <template v-if="layouts.sectionDisplay.checkRiskData">
      <web-guidance-check-data
        @reviewSuccess="handleSuccess"
      ></web-guidance-check-data>
    </template>

    <!--  IBV的文本介绍，以及返回和继续的按钮-->
    <template v-else-if="layouts.sectionDisplay.DecisionLogicTips">
      <div class="animate__animated animate__backInLeft">
        <web-ibv-introduction
          :ibv-provider="ibvProvider"
          v-page-behavior="{
          eventSource:
            assist.dataType.EventSourceEnum.GUIDANCE_ENTER_LEAVE_DL.value,
          path: $route.path,
        }"
        ></web-ibv-introduction>
        <div class="d-flex justify-end">
          <web-btn
            class="mr-5"
            :height="50"
            :theme="'normal'"
            :width="140"
            @click="onClickBack"
          >Back
          </web-btn>
          <web-btn
            :height="50"
            :width="140"
            @click="onClickNext"
          >Continue
          </web-btn>
        </div>
      </div>
    </template>

    <template v-else-if="layouts.sectionDisplay.authorizationPage">
      <web-decision-logic
        v-page-behavior="{eventSource:assist.dataType.EventSourceEnum.GUIDANCE_ENTER_LEAVE_DL_AUTHORIZATION.value,path: $route.path}"
        :requestData="layouts.data.verificationData"
        :ibv-provider="ibvProvider"
        height="calc(100vh - 25px - 25px - 156px)"
        settingStyle="width: 50%; margin-left: 25%"
        @backAction="onDecisionLogicBack"
      ></web-decision-logic>
    </template>

    <template v-else-if="layouts.sectionDisplay.calculationPage">
      <web-guidance-calculation
        :requestData="layouts.data.verificationData"
        v-page-behavior="{eventSource: assist.dataType.EventSourceEnum.GUIDANCE_ENTER_LEAVE_LOAN_AMOUNT.value, path: $route.path}"
      ></web-guidance-calculation>
    </template>
  </div>
</template>
<script>
import { Tool, DataType } from '@/assets/js/util'
import WebDecisionLogic from '@/views/guidance/WebDecisionLogic'
import WebIbvIntroduction from '@/views/guidance/WebIbvIntroduction'
import WebGuidanceCalculation from '@/views/guidance/WebGuidanceCalculation'
import WebGuidanceCheckData from '@/views/guidance/WebGuidanceCheckData'
import WebBtn from '@/components/base/WebBtn'

const tool = new Tool()

export default {
  name: 'web-guidance-decision-logic',
  components: {
    WebIbvIntroduction,
    WebDecisionLogic,
    WebGuidanceCalculation,
    WebGuidanceCheckData,
    WebBtn
  },
  props: {
    verificationData: {
      type: Object,
      default: () => {
      }
    },
    ibvProvider: {
      type: Number
    }
  },
  data () {
    return {
      assist: {
        dataType: DataType
      },
      layouts: {
        data: {
          accountNoTail: '',
          routingNoTail: '',
          resultType: 0,
          iconsStyle: {},
          verificationData: this.verificationData,
          sessionId: tool.generalNanoid(),
          reminderTitleContent: ''
        },
        sectionDisplay: {
          checkRiskData: true,
          calculationPage: false,
          DecisionLogicTips: false,
          authorizationPage: false
        },
        settings: {
          bankInfo: ''
        }
      }
    }
  },
  mounted () {
  },
  methods: {
    onClickBack () {
      this.$emit('back')
    },
    handleSuccess () {
      this.layouts.sectionDisplay.checkRiskData = false
      this.layouts.sectionDisplay.DecisionLogicTips = true
    },
    onClickNext () {
      this.layouts.sectionDisplay.DecisionLogicTips = false
      this.layouts.sectionDisplay.authorizationPage = true
    },
    onDecisionLogicBack () {
      this.layouts.sectionDisplay.authorizationPage = false
      this.layouts.sectionDisplay.calculationPage = true
    }
  }
}
</script>
<style lang="sass" scoped>
.next-btn
  width: 136px
  height: 48px !important
  background: var(--theme_primary) !important
  color: #FFFFFF

.back-btn
  width: 136px
  height: 48px !important
  background: #FFFFFF !important
  border: 1px solid #333640
  box-shadow: 0 4px 16px 0 rgba(92, 125, 171, 0.35)
  color: var(--theme_primary)
  margin-right: 24px

.step-five-note
  margin-top: 32px
  margin-left: 25%
  width: 50%

  &-title
    text-align: center
    font-size: 24px
    margin-top: 150px
    font-weight: 500
    margin-bottom: 40px

  &-card
    border-radius: 16px
    padding-top: 40px
    padding-bottom: 40px
    margin: auto
</style>
