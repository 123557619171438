<template>
  <div :class="sectionActiveClass" v-show="assists.tool.isNotEmpty(sectionActiveClass)">
    <div class="guidance-title">Please confirm your information</div>
    <s-section v-model="layouts.data.currentData"
               ref="sectionForm"
               primary-key="sectionId"
               :actionParameters="parameters"
               :saveParameters="{
                externalKey: 'sectionData',
                payload: {
                  sectionId: this.parameters.sectionId,
                },
               }"
               @syncData="sectionData"
               @syncFormSettings="shareFormSetting"
               data-template-code="D6E1927171C8CF3D735964E0D5CF8B99"
               section-template-code="F3020511DA387F5CF3A1D76E385CAF3E">
    </s-section>

    <v-alert
      v-if="layouts.data.alertControl"
      class="mt-6"
      border="left"
      colored-border
      type="error"
      elevation="2"
    >
      Your information is incomplete or contains invalid data. Please click 'EDIT' to make changes.
    </v-alert>

    <div class="d-flex justify-center mt-10">
      <a @click="layouts.dialog.changePersonalForm=true" class="f-s-20">EDIT</a>
    </div>

    <v-dialog persistent v-model="layouts.dialog.changePersonalForm" max-width="700">
      <v-card>
        <v-card-title>
          <span class="headline p-10 m-b-15">Personal</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="layouts.dialog.changePersonalForm = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <s-form
            ref="personalInformation"
            dataTemplateCode="D6E1927171C8CF3D735964E0D5CF8B99"
            primaryKey="sectionId"
            :actionParameters="parameters"
            :form-setting-from-section="layouts.data.editableFormSetting"
            :saveParameters="{
              externalKey: 'sectionData',
              payload: {
                sectionId: this.parameters.sectionId,
              },
            }"
            @syncTemplate="$refs.personalInformation.load()"
            @saveCallBack="saveCallBack"
            @loadingCallBack="loadingCallBack"
          ></s-form>
        </v-card-text>
        <v-card-actions>
          <web-btn
            class="mr-5"
            :height="50"
            width="40%"
            @click="onClickSave"
          >
          Save
          </web-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="mt-10 d-flex justify-end">
      <web-btn
        class="mr-5"
        :height="50"
        :theme="'normal'"
        :width="140"
        @click="onClickBackIcon"
      >
        Back
      </web-btn>
      <web-btn
        :height="50"
        :width="140"
        @click="onClickContinue"
      >Continue
      </web-btn>
    </div>
  </div>
</template>

<script>
import WebBtn from '@/components/base/WebBtn'
import SSection from '@/components/base/SSection'
import { Tool } from '@/assets/js/util'

export default {
  name: 'web-guidance-personal',
  components: {
    WebBtn,
    SSection
  },
  props: {
    parameters: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      assists: {
        tool: new Tool()
      },
      layouts: {
        data: {
          currentData: {},
          alertControl: false,
          editableFormSetting: {}
        },
        dialog: {
          changePersonalForm: false
        },
        loading: {
          update: false
        }
      },
      sectionActiveClass: ''
    }
  },
  watch: {
    'layouts.dialog.changePersonalForm': {
      handler (val) {
        if (val && this.assists.tool.isNotEmpty(this.$refs.personalInformation)) {
          this.$refs.personalInformation.load()
        }
      }
    }
  },
  methods: {
    onClickSave () {
      if (!this.layouts.loading.update) {
        this.$refs.personalInformation.saveForm()
      }
    },
    saveCallBack (data) {
      this.$refs.personalInformation.$refs.observer.validate().then((validResult) => {
        if (validResult) {
          this.layouts.data.currentData = data
          this.layouts.dialog.changePersonalForm = false
          this.layouts.data.alertControl = false
        }
      })
      this.$emit('verification', data)
    },
    sectionData (data) {
      this.$emit('verification', data)
      this.sectionActiveClass = 'animate__animated animate__backInLeft'
    },
    shareFormSetting (data) {
      this.layouts.data.editableFormSetting = data
    },
    loadingCallBack (loading) {
      this.layouts.loading.update = loading
    },
    onClickContinue () {
      this.$refs.sectionForm.$refs.observer.validate().then(validResult => {
        if (validResult) {
          this.$emit('next')
        } else {
          this.layouts.data.alertControl = true
        }
      })
    },
    onClickBackIcon () {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="sass" scoped>
.guidance-title
  font-size: 24px
  text-align: center
  margin-bottom: 32px
</style>
