<template>
  <div>
    <div class="decision-logic-content">
      <div class="ibv-title">
        We use
        <span class="ibv-title-light" @click="onShowIntroduction">{{ ibvName }}</span>
        to connect your account
      </div>
      <div class="ibv-text">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>
              <v-icon color="secondary" size="19" class="mb-1">mdi-connection</v-icon>
              Effortless Connection
            </v-list-item-title>
            <v-list-item-subtitle class="ml-6 list-subtitle"><span class="ibv-title-light" @click="onShowIntroduction">{{ ibvName }}</span> makes it safe and easy to connect to your account.
            </v-list-item-subtitle>
            <v-list-item-title class="mt-5">
              <v-icon color="secondary" size="19" class="mb-1">mdi-email-fast</v-icon>
              Instant Decision
            </v-list-item-title>
            <v-list-item-subtitle class="ml-6 list-subtitle">You will receive an instant application decision once
              <span class="ibv-title-light" @click="onShowIntroduction">{{ ibvName }}</span> connects your account.
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </div>
      <div class="ibv-introduction">
        <v-btn
          plain
          :depressed="true"
          color="var(--theme_primary)"
          @click="onShowIntroduction">What is {{ ibvName }}?
        </v-btn>
      </div>
    </div>

    <v-dialog v-model="layouts.dialogs.dlIntroduction.display" max-width="400" persistent>
      <v-card>
        <v-card-title>
          <div class="logic-dialog-title">{{ ibvName }} Introduction</div>
          <v-spacer></v-spacer>
          <v-icon @click="onShowIntroduction">
            close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <div class="logic-dialog-content">
            <div class="logic-dialog-text">
              {{ ibvName }} is a secured, private and encrypted service.
              It allows us to view a read-only copy of your bank statement which includes your recent Direct
              Deposits from your employer.
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Tool, DataType } from '@/assets/js/util'
const tool = new Tool()

export default {
  name: 'web-ibv-introduction',
  props: {
    ibvProvider: {
      type: Number
    }
  },
  computed: {
    ibvName () {
      return tool.getEnumTextByValue(DataType.IbvProviderEnum, this.ibvProvider)
    }
  },
  watch: {
  },
  data () {
    return {
      layouts: {
        dialogs: {
          dlIntroduction: {
            display: false
          }
        }
      }
    }
  },
  methods: {
    onShowIntroduction () {
      this.layouts.dialogs.dlIntroduction.display = !this.layouts.dialogs.dlIntroduction.display
    }
  }
}
</script>

<style lang="sass" scoped>
.decision-logic-content
  margin-left: 50%
  -webkit-transform: translateX(-50%)

.ibv-title
  font-size: 24px
  margin-bottom: 32px

  &-light
    color: var(--theme_primary)
    cursor: pointer

.ibv-text
  margin-bottom: 32px

  .list-subtitle
    white-space: normal

.logic-dialog-title
  text-align: center
  font-weight: 600
  font-size: 24px
  width: 328px

.logic-dialog-content
  background: white
  padding: 0 15px

.logic-dialog-text
  font-size: 14px
  font-weight: 400
</style>
