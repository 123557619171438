<template>
  <div :class="employmentActiveClass" v-show="assists.tool.isNotEmpty(employmentActiveClass)">
    <div class="guidance-title">Please confirm your information</div>
    <s-section v-model="layouts.data.currentData"
               ref="sectionForm"
               primary-key="sectionId"
               :actionParameters="parameters"
               :saveParameters="{
                externalKey: 'sectionData',
                payload: {
                  sectionId: this.parameters.sectionId,
                },
               }"
               @syncData="sectionData"
               @syncFormSettings="shareFormSetting"
               data-template-code="282E321E2F2337233E6363FF327C37B2"
               section-template-code="5FB543C5284927E58396114863BC5DC1">
    </s-section>

    <v-alert
      v-if="layouts.data.alertControl"
      class="mt-6"
      border="left"
      colored-border
      type="error"
      elevation="2"
    >
      Your information is incomplete or contains invalid data. Please click 'EDIT' to make changes.
    </v-alert>

    <div class="d-flex justify-center mt-10">
      <a @click="layouts.dialog.changeEmploymentForm=true" class="f-s-20">EDIT</a>
    </div>

    <v-dialog persistent v-model="layouts.dialog.changeEmploymentForm" max-width="700">
      <v-card>
        <v-card-title>
          <span class="headline p-10 m-b-15">Employment</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="layouts.dialog.changeEmploymentForm = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <s-form
            ref="employerInformation"
            dataTemplateCode="282E321E2F2337233E6363FF327C37B2"
            primaryKey="sectionId"
            :actionParameters="parameters"
            :saveParameters="{
              externalKey: 'sectionData',
              payload: {
                sectionId: this.parameters.sectionId,
              }
            }"
            :form-setting-from-section="layouts.data.editableFormSetting"
            @syncTemplate="$refs.employerInformation.load()"
            @saveCallBack="handleCallBack"
            @loadingCallBack="loadingCallBack"
          ></s-form>
        </v-card-text>
        <v-card-actions>
          <web-btn
            class="mr-5"
            :height="50"
            width="40%"
            @click="onClickSave"
          >
            Save
          </web-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="mt-10 d-flex justify-end">
      <web-btn
        class="mr-5"
        :height="50"
        :theme="'normal'"
        :width="140"
        @click="onClickBackIcon"
      >
        Back
      </web-btn>
      <web-btn
        :height="50"
        :width="140"
        @click="onClickContinue"
      >Continue
      </web-btn>
    </div>
  </div>
</template>

<script>
import WebBtn from '@/components/base/WebBtn'
import SSection from '@/components/base/SSection'
import { Tool } from '@/assets/js/util'

export default {
  name: 'web-guidance-employment',
  props: {
    parameters: {
      type: Object,
      required: true
    }
  },
  components: {
    WebBtn,
    SSection
  },
  data () {
    return {
      assists: {
        tool: new Tool()
      },
      layouts: {
        data: {
          currentData: {},
          alertControl: false,
          editableFormSetting: {}
        },
        dialog: {
          changeEmploymentForm: false
        },
        loading: {
          update: false
        }
      },
      employmentActiveClass: ''
    }
  },
  watch: {
    'layouts.dialog.changeEmploymentForm': {
      handler (val) {
        if (val && this.assists.tool.isNotEmpty(this.$refs.employerInformation)) {
          this.$refs.employerInformation.load()
        }
      }
    }
  },
  methods: {
    onClickSave () {
      if (!this.layouts.loading.update) {
        this.$refs.employerInformation.saveForm()
      }
    },
    loadingCallBack (loading) {
      this.layouts.loading.update = loading
    },
    onClickBackIcon () {
      this.$emit('back')
    },
    onClickContinue () {
      this.$refs.sectionForm.$refs.observer.validate().then(validResult => {
        if (validResult) {
          this.$emit('next')
        } else {
          this.layouts.data.alertControl = true
        }
      })
    },
    sectionData (data) {
      this.$emit('verification', data)
      this.employmentActiveClass = 'animate__animated animate__backInLeft'
    },
    shareFormSetting (data) {
      this.layouts.data.editableFormSetting = data
    },
    handleCallBack (data) {
      this.$refs.employerInformation.$refs.observer.validate().then((validResult) => {
        if (validResult) {
          this.layouts.data.currentData = data
          this.layouts.dialog.changeEmploymentForm = false
          this.layouts.data.alertControl = false
        }
      })
      this.$emit('verification', data)
    }
  }
}
</script>

<style lang="sass" scoped>
.guidance-title
  font-size: 24px
  text-align: center
  margin-bottom: 32px
</style>
