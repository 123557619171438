<template>
  <div class="section-wrap pa-4">
    <v-row>
    <template v-for="(field, index) in settings.elements" >
      <v-col :cols="field.foregroundProperty.cols" :key="index">
      <template v-if="assists.tool.isNotEmpty(field.foregroundProperty) && field.foregroundProperty.labelPosition === 'default'">
        <v-row>
          <v-col :cols="8" class="py-2 f-w-900" :class="field.foregroundProperty.labelRowClass">
            {{assists.tool.sectionRender(field, current)}}
          </v-col>
        </v-row>
      </template>

      <template v-else>
        <v-row>
          <v-col cols="auto">
            <span>{{field.elementLabel}}</span>
            <span v-if="assists.tool.isNotEmpty(field.foregroundProperty) && field.foregroundProperty.mandatory" class="red--text">*</span>
          </v-col>
          <v-col class="text-right f-w-900 text-value">
            {{ assists.tool.sectionRender(field, current) }}
          </v-col>
        </v-row>
      </template>
      </v-col>
    </template>
    </v-row>

    <ValidationObserver ref="observer" v-slot="{ validate, reset }">
      <div v-for="(item, index) in formSettings.elements" :key="index">
        <ValidationProvider
          :name="item.elementLabel"
          :rules="item.foregroundProperty.validateRules"
          v-slot="{ errors }"
          style="width: 100%"
        >
          <input v-model="current[item.elementKey]" style="display: none"/>
        </ValidationProvider>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { Tool, DataType } from '@/assets/js/util'
import { PhoenixConfigApi } from '@/api'
import { ajax } from '@/assets/js/util/ajax'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  name: 'SSection',
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: {
    value: {
      type: [Object, Array]
    },
    dataTemplateCode: {
      type: String,
      required: true
    },
    sectionTemplateCode: {
      type: String,
      required: true
    },
    actionParameters: {
      type: Object
    }
  },
  data () {
    return {
      menu: false,
      assists: {
        tool: new Tool(),
        dataType: DataType
      },
      current: {},
      original: {},
      realFormsSettings: {},
      sectionFormsSettings: {}
    }
  },
  computed: {
    settings () {
      const initForm = JSON.parse(JSON.stringify(this.sectionFormsSettings))
      let formSettings = initForm[this.sectionTemplateCode]
      if (this.assists.tool.isEmpty(formSettings)) {
        formSettings = {
          originalProperty: {},
          elements: []
        }
      }
      if (this.assists.tool.isNotEmpty(formSettings.associates)) {
        const elements = formSettings.elements
        elements.map((item, index) => {
          formSettings.associates.forEach(val => {
            if (
              this.assists.tool.isNotEmpty(val) &&
              this.judgmentDisplayAssociate(val) &&
              item.templateElementId === val.sortId
            ) {
              const childrenForm = initForm[val.code]
              elements.splice(index + 1, 0, ...childrenForm.elements)
            }
          })
        })
      }
      return formSettings
    },

    formSettings () {
      const initForm = JSON.parse(JSON.stringify(this.realFormsSettings))
      let formSettings = initForm[this.dataTemplateCode]
      if (this.assists.tool.isEmpty(formSettings)) {
        formSettings = {
          originalProperty: {},
          elements: []
        }
      }
      if (this.assists.tool.isNotEmpty(formSettings.associates)) {
        const elements = formSettings.elements
        elements.map((item, index) => {
          formSettings.associates.forEach(val => {
            if (
              this.assists.tool.isNotEmpty(val) &&
              this.judgmentDisplayAssociate(val) &&
              item.templateElementId === val.sortId
            ) {
              const childrenForm = initForm[val.code]
              elements.splice(index + 1, 0, ...childrenForm.elements)
            }
          })
        })
      }
      return formSettings
    }
  },
  watch: {
    value: {
      handler (val) {
        this.current = { ...val }
      }
    },
    actionParameters (val) {
      if (this.assists.tool.isEmpty(this.original) && this.assists.tool.isNotEmpty(val.sectionId)) {
        this.getFormByCode(this.dataTemplateCode)
        this.getFormByCode(this.sectionTemplateCode)
      }
    }
  },
  mounted () {
    // 避免没拿到sectionId而报错
    if (this.assists.tool.isNotEmpty(this.actionParameters.sectionId)) {
      this.getFormByCode(this.dataTemplateCode)
      this.getFormByCode(this.sectionTemplateCode)
    }
  },
  methods: {
    getFormByCode (templateCode) {
      PhoenixConfigApi.getFormByCode(templateCode, res => {
        // section 和 可修改的formSetting在此拿，可修改的form setting需要传递给父组件，以便s-form可再次使用
        if (templateCode === this.dataTemplateCode) {
          this.realFormsSettings = res
          this.$emit('syncFormSettings', res)
        } else {
          this.sectionFormsSettings = res
        }

        // 将read url传给父组件
        const formSettings = res[this.dataTemplateCode]
        // 如是携带数据，则不请求接口
        if (this.assists.tool.isNotEmpty(this.value)) {
          this.current = this.value
          return
        }
        if (this.assists.tool.isNotEmpty(formSettings?.actionProperty)) {
          this.load()
        }
      })
    },
    load () {
      let url = this.realFormsSettings[this.dataTemplateCode].actionProperty.read.url
      if (this.assists.tool.isNotEmpty(this.actionParameters)) {
        url = url.replace(
          /\{([^}]+)\}/g,
          (_, value) => this.actionParameters[value]
        )
      }
      ajax.get(url, {}, res => {
        this.current = { ...res }
        this.original = { ...res }

        this.$emit('syncData', { ...res })
      })
    },
    judgmentDisplayAssociate (associate) {
      return associate.items.every((items) => {
        return this.current[items.key] === items.value
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.section-wrap
  background: var(--theme_secondary_opacity_high)
  border-radius: 15px
  box-shadow: rgba(0, 0, 0, 0.3) 0 15px 30px, rgba(0, 0, 0, 0.22) 0 12px 9px
  font-size: 16px

.text-value
  white-space: nowrap
  display: block
  overflow: hidden
  text-overflow: ellipsis
</style>
